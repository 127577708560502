body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'STHEITI';
  src: local('STHEITI'), url(./static/STHEITI.ttf) format('ttf');
}

/* スムーズにスクロールするためのおまじない */
html{
  scroll-behavior: smooth;
}
/* スムーズにスクロールするためのおまじない */

.show {
  opacity : 1;
  transform : translate(0, 0);
  transition : all 300ms;
}

.hidden {
  opacity : 0;
  transform : translate(0, 45px);
}

@media screen and (max-width:485px) {
  h1 {
    font-size: 35px;
  }
  
  h2 {
    font-size: 30px;
  }
  
  h3 {
    font-size: 25px;
  }
  
  h4 {
    font-size: 20px;
  }
  
  p, li {
    font-size: 15px;
  }

  .sp {
    font-size: 12.5px;
  }
}

@media screen and (min-width:486px) {
  h1 {
    font-size: 40px;
  }
  
  h2 {
    font-size: 35px;
  }
  
  h3 {
    font-size: 30px;
  }
  
  h4 {
    font-size: 25px;
  }
  
  p, li {
    font-size: 20px;
  }

  .sp {
    font-size: 17.5px;
  }
}


/* アニメーション設定 */
.arrow1 {
  width: 15px;
  margin: 0 auto;
  -webkit-animation: arrow1 2s linear 0s infinite normal;
  animation: arrow1 2s linear 0s infinite normal;
}

.arrow2 {
  width: 15px;
  margin: 0 auto;
  -webkit-animation: arrow2 2s linear 0s infinite;
  animation: arrow2 2s linear 0s infinite;
}

.arrow1 span,
.arrow2 span {
  position: relative;
  display: block;
  left: 50%;
}

.arrow1 span {
  margin-top: -10px;
}

@media screen and (max-width: 950px) {
  .arrow1 span:before,
  .arrow2 span:before {
    content: '';
    width: 15px;
    height: 15px;
    border: 0;
    border-top: solid 2px #6D6C67;
    border-right: solid 2px #6D6C67;
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
    position: absolute;
    top: 500px;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

@media screen and (min-width:951px) and (max-width:1060px)  {
  .arrow1 span:before,
  .arrow2 span:before {
    content: '';
    width: 15px;
    height: 15px;
    border: 0;
    border-top: solid 2px #6D6C67;
    border-right: solid 2px #6D6C67;
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
    position: absolute;
    top: 600px;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

@media screen and (min-width:1061px) {
  .arrow1 span:before,
  .arrow2 span:before {
    content: '';
    width: 15px;
    height: 15px;
    border: 0;
    border-top: solid 2px #6D6C67;
    border-right: solid 2px #6D6C67;
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
    position: absolute;
    top: 700px;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

@keyframes arrow1 {
  0% {
    -webkit-transform: translate3d(-50%, 0, 0);
    transform: translate3d(-50%, 0, 0);
  }

  20% {
    -webkit-transform: translate3d(-50%, 0, 0);
    transform: translate3d(-50%, 0, 0);
  }

  40% {
    -webkit-transform: translate3d(-50%, 10px, 0);
    transform: translate3d(-50%, 10px, 0);
  }

  60% {
    -webkit-transform: translate3d(-50%, 10px, 0);
    transform: translate3d(-50%, 10px, 0);
  }

  80% {
    -webkit-transform: translate3d(-50%, 0, 0);
    transform: translate3d(-50%, 0, 0);
  }

  100% {
    -webkit-transform: translate3d(-50%, 0, 0);
    transform: translate3d(-50%, 0, 0);
  }
}

@keyframes arrow2 {
  0% {
    -webkit-transform: translate3d(-50%, 10px, 0);
    transform: translate3d(-50%, 10px, 0);
  }

  20% {
    -webkit-transform: translate3d(-50%, 20px, 0);
    transform: translate3d(-50%, 20px, 0);
  }

  40% {
    -webkit-transform: translate3d(-50%, 20px, 0);
    transform: translate3d(-50%, 20px, 0);
  }

  60% {
    -webkit-transform: translate3d(-50%, 20px, 0);
    transform: translate3d(-50%, 20px, 0);
  }

  80% {
    -webkit-transform: translate3d(-50%, 10px, 0);
    transform: translate3d(-50%, 10px, 0);
  }

  100% {
    -webkit-transform: translate3d(-50%, 10px, 0);
    transform: translate3d(-50%, 10px, 0);
  }
}