.backimg {
    margin: 0;
}

.backimg::before {
    background-image: url(static/backgroundIMG.svg);/* 擬似要素に背景画像 */
    background-size: 100px;/* cover指定 */
    content: "";/* 画像を表示させる為に必要*/
    /* display: block; */
    position: fixed;
    width: 100%;/* 横幅を画面全体 */
    height: 100%;/* 縦幅を画面全体 */
    /* top: 0; */
    /* left: 0; */
    z-index: -1;/*背景画像になるように重ね順を-1*/
}

.Toastify__toast-container {
    width: auto;
    display: inline-block;
}

h1, h2, h3, h4, h5, p, li {
    color: #6D6C67;
}

.spinnerDiv {
    height: 100vh;
    position: relative;
}

.spinner {
    text-align: center;
    position: fixed;
	top: 45%;
	left: 50%;
    margin-left: -27px;
  }
  
  .spinner > div {
    width: 18px;
    height: 18px;
    background-color: #6D6C67;
    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  }
  
  .spinner .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }
  
  .spinner .bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
  }

  @-webkit-keyframes sk-bouncedelay {
    0%, 80%, 100% { -webkit-transform: scale(0) }
    40% { -webkit-transform: scale(1.0) }
  }
  
  @keyframes sk-bouncedelay {
    0%, 80%, 100% { 
      -webkit-transform: scale(0);
      transform: scale(0);
    } 40% { 
      -webkit-transform: scale(1.0);
      transform: scale(1.0);
    }
  }